$blurple-600: #1dae1d; // Iris
$blurple-500: #3ae13a; // Brand purple
$blurple-400: #4cdf4c; // Medium slate blue
$blurple-300: #5bdc5b; // Faded Blue

$ui-base-color: #283728;
$ui-primary-color: #9bc89b;
$ui-secondary-color: #d9e8d9;
$ui-highlight-color: #4cc34c;

$ui-button-background-color: $blurple-500;
$ui-button-focus-background-color: $blurple-600;
$ui-button-focus-outline-color: $blurple-400;
$ui-button-tertiary-color: $blurple-300;
$ui-button-tertiary-border-color: $blurple-300;
$ui-button-tertiary-focus-background-color: $blurple-600;

@import 'application';
